<template>
  <div class="container">
    <div class="row justify-content-center mt-4">
      <div class="col-md-12">


        <div class="card">

          <div class="card-body">
            <div class="card-title mb-4">
              <div class="row">
                <div class="col-12 ml-3">
                  <h2 class="d-block"
                      style="font-size: 1.5rem; font-weight: bold; text-align: center">Подписать
                    документ с помощью электронно-цифровой подписи</h2>
                </div>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">1. Выберите ключ</label>
              <div class="col-md-9">
                <div class="custom-file-upload">
                  <label for="file-upload" class="custom-file-upload1">
                    <!--                    <i class="fa fa-cloud-upload"></i> Выберите файл-->
                  </label>
                  <input id="file-upload" type="file" @change="keyFileHandler"/>
                </div>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">2. Введите пароль ключа</label>
              <div class="col-md-2">
                <input type="text" class="form-control" name="password" id="password"
                       placeholder="Пароль" title="Введите пароль" v-model="password">
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">3. Файл для подписи</label>
              <div class="col-md-9">
                <a :href="'https://signature.uib.kz/'+signatureFilesByUser[0].file.file_url">{{signatureFilesByUser[0].file.file_name}}</a>

                <!--                <div class="custom-file-upload">-->
                <!--                  <label for="file-upload-2" class="custom-file-upload1">-->
                <!--&lt;!&ndash;                    <i class="fa fa-cloud-upload"></i> Выберите файл&ndash;&gt;-->
                <!--                  </label>-->
                <!--                  <input id="file-upload-2" type="file" @change="docFileHandler" />-->
                <!--                </div>-->
              </div>
            </div>

            <div class="form-group mt-4">
              <div class="col-md-12 text-center">
                <button class="btn btn-lg btn-success" type="submit" @click="submit"><i
                    class="glyphicon glyphicon-ok-sign"></i> Подписать
                </button>
              </div>
            </div>

            <!--            <div v-for="(item, index) in certInfos" :key="index">-->
            <!--              {{item}}-->
            <!--            </div>-->

            <!--                        <div class="form-group row mt-4" v-if="response">-->

            <!--                            <div class="col-md-6 text-center">-->
            <!--                                <a :href="'https://signature.uib.kz/'+cmsLink">Скачать CMS для проверки в ezSigner</a>-->
            <!--                            </div>-->
            <!--                            <div class="col-md-6 text-center">-->
            <!--                                <a :href="'https://signature.uib.kz/'+docLink">Скачать подписанный файл</a>-->
            <!--                            </div>-->

            <!--                        </div>-->


          </div>


        </div>

      </div>

      <div class="col-md-12 mt-4" v-if="certInfos.length != 0">
        <div class="card">

          <div class="card-body">
            <div class="card-title mb-4">
              <div class="row">
                <div class="col-12 ml-3">
                  <h2 class="d-block"
                      style="font-size: 1.5rem; font-weight: bold; text-align: center">Информация об
                    электронном документе</h2>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in certInfos" :key="index">
              <h4>ИИН/БИН: {{item.serialNumber}}</h4>
              <br>
              <h4>ФИО: {{item.commonName}}&nbsp;{{item.givenName}}</h4>
              <br>
              <h4>Серийный номер сертификата: {{item.certificateSerialNumber}}</h4>
              <br>
              <h4>Срок действия сертификата: {{item.notBefore}} - {{item.notAfter}}</h4>
              <br>
              <h4>Дата подписания : {{item.time}}</h4>
              <br>
              <hr>
            </div>


          </div>


        </div>
      </div>



    </div>
  </div>

</template>

<script>

import axios from "axios";
import httpClient from "../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";


export default {
  name: "CompanySignature",

  data() {
    return {
      password: '',
      keyFile: null,
      response: false,
      docLink: '',
      cmsLink: '',
      signatureFilesByUser: [],
      certInfos: [],
      fileId: 0

    }
  },
  methods: {

    async GET_SIGNATURE_FILES_BY_USER() {
      try {
        const {
          status,
          data
        } = await httpClient.get('questionnaire/signature-files/signatured-files-by-user' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          console.log(data.success, "user")
          return data.success

        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async submit() {

      let formData = new FormData();

      formData.append('password', this.password);
      formData.append('ecp_file', this.keyFile);
      formData.append('file_id', this.fileId);

      console.log(Object.fromEntries(formData), 'hello from formdata')


      await httpClient.post('questionnaire/signature-files/file-sent' + '?access-token=' + getCookie('ACCESS_TOKEN'),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(async res => {
        console.log('SUCCESS!!', res)

        this.cmsLink = res.data.cms
        this.docLink = res.data.file


        this.certInfos = await this.GET_INFO_CERT()

      }).catch(err => {
        console.log('FAILURE!!', err)
      });


    },
    keyFileHandler(e) {
      let file = e.target.files[0];
      this.keyFile = file;
    },

    async GET_INFO_CERT() {
      try {
        const {
          status,
          data
        } = await axios.get('https://signature.uib.kz/infoCert.php?file_id=' + this.signatureFilesByUser[0].file_id);
        if (status === 200) {

          console.log(data, "INFOCERT")

          return data
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
  },
  async created() {
    this.signatureFilesByUser = await this.GET_SIGNATURE_FILES_BY_USER();
    console.log(this.signatureFilesByUser, "signatureFilesByUser")
    this.fileId = this.signatureFilesByUser[0].file_id
    this.certInfos = await this.GET_INFO_CERT();
    //console.log(this.certInfos)
  },
}
</script>

<style scoped>
/*.custom-file-upload input[type="file"] {*/
/*  display: none;*/
/*}*/
/*.custom-file-upload .custom-file-upload1 {*/
/*  border: 1px solid #ccc;*/
/*  display: inline-block;*/
/*  padding: 6px 12px;*/
/*  cursor: pointer;*/
/*}*/

</style>